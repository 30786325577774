export class SearchParams {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'aggregate_overall_rating[$gte]'?: string;
  name?: string;
  fulltext?: string;
  category_sort_id?: string;
  network_id?: string;
  geo_location?: string;
  locale?: string;
  data_language?: string;
  procedure_id?: string;
  search_specialty_id?: string;
  field_specialty_ids?: string[];
  search_specialty_incentive_id?: string;
  page?: string;
  filters?: string;
  radius?: string;
  limit?: string;
  sort?: string;
  contract_accepting_new_patients?: string;
  provider_type?: string;
  cqms?: string;
  professional_gender?: string;
  is_pcp?: string;
  is_choice_pcp?: string;
  is_certified?: string;
  professional_languages?: string;
  location_languages?: string;
  award_type_codes?: string;
  average_wait_time?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'average_wait_time[$lte]'?: string;
  bdc_type_codes?: string;
  expertise_codes?: string;
  facility_location_id?: string;
  group_affiliation_ids?: string;
  hospital_affiliation_ids?: string;
  grouphealth_providers_type_codes?: string;
  gh_wayfinding_providers_values?: string;
  bcbst_mat_providers_values?: string;
  enhanced_benefits_values?: string;
  has_incentive?: string;
  freestanding_facilities_type_codes?: string;
  island_type_codes?: string;
  mammography_providers_values?: string;
  mammogram_providers_values?: string;
  embold_providers_values?: string;
  retail_health_values?: string;
  mobile_provider_values?: string;
  office_based_colonoscopy_values?: string;
  transaction_id?: string;
  account_id?: string;
  ci?: string;
  coe_type_codes?: string;
  referral_network_ids?: string;
  leapfrog_grade_values?: string;
  member_number?: string;
  dob?: string;
  client_canonical_id?: string;
  x12_code?: string;
  provider_id?: string;
  location_id?: string;
  cache?: 'true' | 'false';
  ahn_providers_values?: string;
  urgent_care_values?: string;
  pcmh_providers_values?: string;
  provider_type_name?: string;
  provider_type_description?: string;
  telehealth_values?: string;
  extended_hours_type_codes?: string;
  handicap_accessible_values?: string;
  ages_treated_type_codes?: string;
  disorders_treated_type_codes?: string;
  treatment_methods_type_codes?: string;
  ada_building_amenities_type_codes?: string;
  age_limitations?: string;
  national_pcp?: string;
  regional_pcp?: string;
  local_pcp?: string;
  vbidri_providers_values?: string;
  enhanced_provider_values?: string;
  cultural_competency_values?: string;
  provider_services_type_codes?: string;
  adv_professionals_values?: string;
  nmh_facilities_values?: string;
  quality_score_type_codes?: string;
  verified_data?: boolean;
  provider_verified_values?: string;
  essential_provider_values?: string;
  race_type_codes?: string;
  ethnicity_type_codes?: string;
  bdtc_values?: string;
  billing_code?: string;
  quality_blue_type_codes?: string;
  parish_type_codes?: string;
  concierge_values?: string;
  preferred_pharmacy_values?: string;
  accept_medicaid_values?: string;
  virtual_only?: boolean;
  offers_virtual_care?: boolean;
  direct_delegated_type_codes?: string;
  medication_assisted_treatment_values?: string;
  hiv_experienced_values?: string;
  option1_providers_values?: string;
  virtual_connect_providers_values?: string;
  peaq_flag_values?: string;
  value_choice_providers_values?: string;

  constructor(
    obj?: SearchParams,
    tier_code?: string,
    has_group_tiers?: boolean
  ) {
    if (obj) {
      this['aggregate_overall_rating[$gte]'] = obj[
        'aggregate_overall_rating[$gte]'
      ]
        ? obj['aggregate_overall_rating[$gte]'].replace(/<|>/g, '')
        : null;
      this.fulltext = obj.name || obj.fulltext || null;
      this.category_sort_id =
        obj.search_specialty_id && obj.field_specialty_ids
          ? obj.search_specialty_id
          : null;
      this.network_id = obj.network_id || null;
      this.geo_location = obj.geo_location || null;
      this.locale = obj.locale || 'en';
      this.data_language = this.locale;
      this.procedure_id = obj.procedure_id || null;
      this.search_specialty_incentive_id = obj.search_specialty_incentive_id || null;
      this.search_specialty_id = !obj.field_specialty_ids
        ? obj.search_specialty_id
        : null;
      this.field_specialty_ids = obj.field_specialty_ids || null;
      this.page = obj.page || '1';
      this.filters = obj.filters || null;
      this.radius = obj.radius || null;
      this.limit = obj.limit || '10';
      this.sort = obj.sort || null;
      this.contract_accepting_new_patients =
        obj.contract_accepting_new_patients || null;
      this.provider_type = obj.provider_type || null;
      this.cqms = obj.cqms || null;
      this.professional_gender = obj.professional_gender || null;
      this.is_certified = obj.is_certified || null;
      this.is_pcp = obj.is_pcp || null;
      this.is_choice_pcp = obj.is_choice_pcp || null;
      this.professional_languages = obj.professional_languages || null;
      this.location_languages = obj.location_languages || null;
      this.award_type_codes = obj.award_type_codes || null;
      this['average_wait_time[$lte]'] = obj.average_wait_time
        ? obj.average_wait_time.replace('>', '')
        : null;
      this.bdc_type_codes = obj.bdc_type_codes || null;
      this.expertise_codes = obj.expertise_codes || null;
      this.group_affiliation_ids = obj.group_affiliation_ids || null;
      this.hospital_affiliation_ids = obj.hospital_affiliation_ids || null;
      this.grouphealth_providers_type_codes =
        obj.grouphealth_providers_type_codes || null;
      this.gh_wayfinding_providers_values =
        obj.gh_wayfinding_providers_values || null;
      this.bcbst_mat_providers_values = obj.bcbst_mat_providers_values || null;
      this.enhanced_benefits_values = obj.enhanced_benefits_values || null;
      this.has_incentive = obj.has_incentive || null;
      this.island_type_codes = obj.island_type_codes || null;
      this.freestanding_facilities_type_codes =
        obj.freestanding_facilities_type_codes || null;
      this.mammography_providers_values =
        obj.mammography_providers_values || null;
      this.mammogram_providers_values = obj.mammogram_providers_values || null;
      this.embold_providers_values = obj.embold_providers_values || null;
      this.retail_health_values = obj.retail_health_values || null;
      this.mobile_provider_values = obj.mobile_provider_values || null;
      this.office_based_colonoscopy_values =
        obj.office_based_colonoscopy_values || null;
      this.transaction_id = obj.transaction_id || null;
      this.account_id = obj.account_id || null;
      this.ci = obj.ci || null;
      this.coe_type_codes = obj.coe_type_codes || null;
      this.referral_network_ids = obj.referral_network_ids || null;
      this.leapfrog_grade_values = obj.leapfrog_grade_values || null;
      if (tier_code && has_group_tiers) {
        this['restrict[tiers:' + tier_code.toLowerCase() + '][$lte]'] =
          obj['tiers:' + tier_code.toLowerCase()] || null;
      } else if (tier_code) {
        this['restrict[tiers:' + tier_code.toLowerCase() + '][$eq]'] =
          obj['tiers:' + tier_code.toLowerCase()] || null;
      }
      this.member_number = obj.member_number || null;
      this.dob = obj.dob || null;
      this.client_canonical_id = obj.client_canonical_id || null;
      this.x12_code = obj.x12_code || null;
      this.provider_id = obj.provider_id || null;
      this.location_id = obj.location_id || null;
      this.ahn_providers_values = obj.ahn_providers_values || null;
      this.urgent_care_values = obj.urgent_care_values || null;
      this.pcmh_providers_values = obj.pcmh_providers_values || null;
      this.provider_type_name = obj.provider_type_name || null;
      this.provider_type_description = obj.provider_type_description || null;
      this.telehealth_values = obj.telehealth_values || null;
      this.extended_hours_type_codes = obj.extended_hours_type_codes || null;
      this.handicap_accessible_values = obj.handicap_accessible_values || null;
      this.ages_treated_type_codes = obj.ages_treated_type_codes || null;
      this.disorders_treated_type_codes =
        obj.disorders_treated_type_codes || null;
      this.treatment_methods_type_codes =
        obj.treatment_methods_type_codes || null;
      this.ada_building_amenities_type_codes =
        obj.ada_building_amenities_type_codes || null;
      this.age_limitations = obj.age_limitations || null;
      this.national_pcp = obj.national_pcp || null;
      this.regional_pcp = obj.regional_pcp || null;
      this.local_pcp = obj.local_pcp || null;
      this.vbidri_providers_values = obj.vbidri_providers_values || null;
      this.enhanced_provider_values = obj.enhanced_provider_values || null;
      this.cultural_competency_values = obj.cultural_competency_values || '';
      this.provider_services_type_codes =
        obj.provider_services_type_codes || '';
      this.adv_professionals_values = obj.adv_professionals_values || '';
      this.nmh_facilities_values = obj.nmh_facilities_values || '';
      this.quality_score_type_codes = obj.quality_score_type_codes || '';
      this.verified_data = obj.verified_data || null;
      this.provider_verified_values = obj.provider_verified_values || '';
      this.essential_provider_values = obj.essential_provider_values || '';
      this.race_type_codes = obj.race_type_codes || '';
      this.ethnicity_type_codes = obj.ethnicity_type_codes || '';
      this.bdtc_values = obj.bdtc_values || '';
      this.billing_code = obj.billing_code || '';
      this.quality_blue_type_codes = obj.quality_blue_type_codes || '';
      this.parish_type_codes = obj.parish_type_codes || '';
      this.concierge_values = obj.concierge_values || '';
      this.preferred_pharmacy_values = obj.preferred_pharmacy_values || '';
      this.accept_medicaid_values = obj.accept_medicaid_values || '';
      this.virtual_only = obj.virtual_only || null;
      this.offers_virtual_care = obj.offers_virtual_care || null;
      this.direct_delegated_type_codes = obj.direct_delegated_type_codes || '';
      this.medication_assisted_treatment_values = obj.medication_assisted_treatment_values || '';
      this.hiv_experienced_values = obj.hiv_experienced_values || '';
      this.option1_providers_values = obj.option1_providers_values || '';
      this.virtual_connect_providers_values = obj.virtual_connect_providers_values || '';
      this.peaq_flag_values = obj.peaq_flag_values || '';
      this.value_choice_providers_values =
        obj.value_choice_providers_values || '';
    }
  }
}
